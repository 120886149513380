import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PaymentCards from "./PaymentCards";
import { ReactComponent as Logo } from "../../img/logo-gk.svg";

const logoStyles = {
  maxWidth: 75,
  height: "auto",
};

const Footer = () => {
  return (
    <footer id="footer" className="mt-4">
      <Container>
        <Row>
          <Col>
            <p className="mb-3">
              &copy; Copyright {new Date().getFullYear()} Gamett &amp; King CPAs
            </p>
            <Logo style={logoStyles} />
          </Col>
          <Col className="text-end">
            <PaymentCards />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
