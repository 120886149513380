import { useRef, useEffect } from "react";
import { useScript } from "./use-script";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? "https://gateway.clearent.net"
    : "https://gateway-sb.clearent.net";

export function useClearentSDK() {
  const sdk = useRef(null);
  const status = useScript(`${apiUrl}/js-sdk/js/clearent-host.js`);

  useEffect(() => {
    const init = () => {
      sdk.current.init({
        baseUrl: apiUrl,
        pk: process.env.REACT_APP_CLEARENT_PUBLIC_KEY,
        showValidationMessages: false,
        clearFormOnSuccess: true,
        cardPlaceholder: "Card Number *",
        cvcPlaceholder: "CVC *",
        expDatePlaceholder: "MM / YY *",
        styles:
          "@media (max-width:768px) { input.form-control {font-size: 1rem !important} #exp-date-row > div:first-child { padding: 0 !important } #exp-date-row > div:nth-child(2) {padding: 0 !important}  } .form-control{ color: #212529; border-radius: 0.25rem; padding: 1rem 0.75rem; height: calc(3.5rem + 2px); line-height: 1.25;} .form-control::placeholder{color: #212529} .form-control::-webkit-placeholder{color: #212529} .form-control::-moz-placeholder{color: #212529} .input-group{margin-bottom: 1rem;} #exp-date-group .form-control, #cvc-group .form-control, #exp-date-group .input-group-text, #cvc-group .input-group-text {border-top: 1px solid #ced4da; border-radius: 0.25rem;} #card-group .input-group-text, #exp-date-group .input-group-text, #cvc-group .input-group-text {border-radius: 0.25rem 0 0 0.25rem} #exp-date-row > div:first-child { padding-right: 0.75rem;} #exp-date-row > div:first-child input { border-right: 1px solid #ced4da; border-radius: 0.25rem } #exp-date-row > div:nth-child(2) {padding-left: 0.75rem};  #exp-date-row > div:nth-child(2) input {border-radius: 0.25rem} .input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {border-radius: 0.25rem}",
      });
    };

    if (status === "ready") {
      sdk.current = window.ClearentSDK;
      init();
    }
  }, [status]);

  const getPaymentToken = () => {
    return sdk.current.getPaymentToken();
  };

  return {
    getPaymentToken,
    status,
  };
}
