import React from "react";
import { ReactComponent as Amex } from "../../../img/payment-amex.svg";
import { ReactComponent as Visa } from "../../../img/payment-visa.svg";
import { ReactComponent as Mastercard } from "../../../img/payment-mastercard.svg";
import { ReactComponent as Discover } from "../../../img/payment-discover.svg";
import styles from "./PaymentCards.module.scss";

const PaymentCards = () => {
  return (
    <p className={styles["card-container"]}>
      <Amex />
      <Visa />
      <Mastercard />
      <Discover />
    </p>
  );
};

export default PaymentCards;
