import React from "react";
import { ReactComponent as Logo } from "../../img/logo-gamettking.svg";

const Contact = () => {
  return (
    <>
      <Logo className="mb-3" />
      <p>
        Gamett &amp; King 2600 <br />
        Paseo Verde Parkway Suite 250
        <br />
        Henderson, NV 89074
        <br />
        Telephone: (702) 433-1040
        <br />
        Fax: (702) 433-3449
      </p>
    </>
  );
};

export default Contact;
