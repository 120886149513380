import Joi from "joi";

export function validatePaymentDetails(payload) {
  const schema = Joi.object({
    amount: Joi.string().required().label("Amount"),
    reference: Joi.any().label("Invoice Number"),
    clientName: Joi.any().label("Client Name"),
    cardHolderName: Joi.string().required().label("Name on Card"),
    emailAddress: Joi.string()
      .email({ tlds: false })
      .required()
      .label("Email Address"),
    billingAddress1: Joi.string().required().label("Billing Address"),
    billingAddress2: Joi.string().allow("").label("Billing Address 2"),
    city: Joi.string().required().label("City"),
    state: Joi.string().required().label("State"),
    zip: Joi.string().min(5).max(5).label("Zip"),
  }).or("reference", "clientName");

  const { error } = schema.validate(payload);

  if (
    error &&
    error.message ===
      '"value" must contain at least one of [Invoice Number, Client Name]'
  ) {
    throw new Error("Invoice Number or Client Name is required");
  }

  if (error) throw error;
}
