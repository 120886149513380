import Header from "./components/Header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PaymentForm from "./components/PaymentForm";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Container>
        <Row>
          <Col>
            <Contact />
          </Col>
          <Col lg={8}>
            <PaymentForm />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
