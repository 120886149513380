import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { useClearentSDK } from "../../hooks/use-clearent-sdk";
import USStates from "./states.json";
import { validatePaymentDetails } from "./validate";
import styles from "./PaymentForm.module.scss";
import ReCAPTCHA from "react-google-recaptcha";

const defaultState = {
  amount: "",
  reference: "",
  clientName: "",
  cardHolderName: "",
  emailAddress: "",
  billingAddress1: "",
  billingAddress2: "",
  city: "",
  state: "",
  zip: "",
};

function toUSDCurrency(value = 0) {
  return parseFloat(value).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

function cleanKeys(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
}

const PaymentForm = () => {
  const ClearentSDK = useClearentSDK();
  const [attempt, setAttempt] = useState(0);
  const [formData, setFormData] = useState({ ...defaultState });
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [formDisabled, setFormDisabled] = useState(true);
  const recaptcha = useRef();

  useEffect(() => {
    if (ClearentSDK.status === "ready") setFormDisabled(false);
  }, [ClearentSDK.status]);

  const handleChange = ({ target }) => {
    const newFormData = { ...formData };
    const { name, value } = target;
    newFormData[name] = value;
    setFormData(newFormData);
  };

  const handleAmountBlur = ({ target }) => {
    if (target.value) {
      const newFormData = { ...formData };
      let amount = toUSDCurrency(target.value || 0);
      newFormData["amount"] = amount.substring(1).replaceAll(",", "");
      setFormData(newFormData);
    }
  };

  const handleCaptcha = async () => {
    const token = await recaptcha.current.executeAsync();
    return token;
  };

  const resetRecaptcha = () => {
    recaptcha.current.reset();
  };

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();
      const toValidate = cleanKeys(formData);
      validatePaymentDetails(toValidate);
      setFormDisabled(true);
      handleClearMessages();
      setAttempt(attempt + 1);
      if (attempt > 2) throw new Error("Too many attempts");
      const cToken = await handleCaptcha();

      ClearentSDK.getPaymentToken()
        .then((response) => {
          axios
            .post("/.netlify/functions/process-payment", formData, {
              headers: {
                mobilejwt: response?.payload["mobile-jwt"]?.jwt,
                captchatoken: cToken,
              },
            })
            .then((response) => {
              setSuccessMsg(response?.data?.message);
              handleClearForm();
            })
            .catch((err) => {
              setErrorMsg(err.response?.data?.message);
            })
            .finally(() => {
              resetRecaptcha();
              setFormDisabled(false);
            });
        })
        .catch((error) => {
          const errMessage =
            (error.payload?.error && error.payload?.error["error-message"]) ||
            "Unable to Tokenize Credit Card";
          setErrorMsg(errMessage);
          setFormDisabled(false);
          resetRecaptcha();
        });
    } catch (error) {
      let message = error.message;
      if (error?.response?.data?.message) {
        message = error.response.data.message;
      }
      if (message === "Too many attempts") {
        setErrorMsg("Too many attempts. Please try again.");
        handleClearForm();
        resetRecaptcha();
        setFormDisabled(false);
        return;
      }
      setErrorMsg(message);
      setFormDisabled(false);
    }
  };

  const handleClearForm = () => {
    setFormData({ ...defaultState });
    setAttempt(0);
  };

  const handleClearMessages = () => {
    setErrorMsg("");
    setSuccessMsg("");
  };

  return (
    <>
      <Row>
        <Col>
          <Form onSubmit={handleFormSubmit} className={styles["form"]}>
            {formDisabled && (
              <div className={styles["spinner-container"]}>
                <Spinner animation="grow" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            <fieldset disabled={formDisabled}>
              <Row xs={1} lg={2}>
                <Form.Group as={Col} className="mb-3">
                  <FloatingLabel label="Payment Amount *">
                    <Form.Control
                      required
                      onChange={handleChange}
                      onBlur={handleAmountBlur}
                      value={formData.amount}
                      name="amount"
                      type="number"
                      aria-label="Enter a payment amount"
                      placeholder="Enter a payment amount"
                      step="0.01"
                      min={0.01}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                  <FloatingLabel label="Reference / Invoice Number ">
                    <Form.Control
                      onChange={handleChange}
                      value={formData.reference}
                      name="reference"
                      type="text"
                      aria-label="Enter a reference or invoice number"
                      placeholder="Enter a reference or invoice number"
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} className="mb-3">
                  <FloatingLabel label="Client / Company Name">
                    <Form.Control
                      onChange={handleChange}
                      value={formData.clientName}
                      name="clientName"
                      type="text"
                      aria-label="Enter a client or company name"
                      placeholder="Enter a client or company name"
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <div id="payment-form" />

              <Row xs={1} lg={2}>
                <Form.Group as={Col} className="mb-3">
                  <FloatingLabel label="Name on Card *">
                    <Form.Control
                      required
                      aria-label="Name on Card"
                      placeholder="Name on Card"
                      name="cardHolderName"
                      onChange={handleChange}
                      value={formData.cardHolderName}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} className="mb-3">
                  <FloatingLabel label="Email Address *">
                    <Form.Control
                      required
                      type="email"
                      aria-label="Email Address"
                      placeholder="Email Address"
                      name="emailAddress"
                      onChange={handleChange}
                      value={formData.emailAddress}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Billing Address *">
                    <Form.Control
                      required
                      aria-label="Billing Address"
                      placeholder="Billing Address"
                      name="billingAddress1"
                      onChange={handleChange}
                      value={formData.billingAddress1}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3">
                  <FloatingLabel label="Billing Address 2">
                    <Form.Control
                      aria-label="Billing Address 2"
                      placeholder="Billing Address 2"
                      name="billingAddress2"
                      onChange={handleChange}
                      value={formData.billingAddress2}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row className="mb-3" xs={1} lg={3}>
                <Form.Group as={Col} className="mb-3 mb-lg-0">
                  <FloatingLabel label="City *">
                    <Form.Control
                      required
                      aria-label="Billing Address City"
                      placeholder="Billing Address City"
                      name="city"
                      onChange={handleChange}
                      value={formData.city}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} className="mb-3 mb-lg-0">
                  <FloatingLabel label="State *">
                    <Form.Select
                      required
                      onChange={handleChange}
                      value={formData.state}
                      name="state"
                    >
                      <option value="">Choose...</option>
                      {USStates.map((state) => (
                        <option
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} className="mb-3 mb-lg-0">
                  <FloatingLabel label="Zip *">
                    <Form.Control
                      required
                      aria-label="Billing Address Zip"
                      placeholder="Billing Address Zip"
                      name="zip"
                      minLength={5}
                      maxLength={5}
                      onChange={handleChange}
                      value={formData.zip}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <hr />
              <Row className="my-5">
                <Col>
                  <p>
                    <b>Total amount due</b>
                  </p>
                </Col>
                <Col>
                  <h3 className="text-end">
                    {toUSDCurrency(formData.amount || 0)}
                  </h3>
                </Col>
              </Row>
              <Row className="justify-content-lg-end">
                <Col xs={12} lg={4}>
                  <Button className={styles["submit-button"]} type="submit">
                    Submit Payment
                  </Button>
                </Col>
              </Row>
            </fieldset>
            <ReCAPTCHA
              ref={recaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              size="invisible"
            />
          </Form>
        </Col>
      </Row>
      <Row className="mt-3">
        {successMsg && (
          <Alert
            onClose={() => setSuccessMsg("")}
            dismissible
            variant="success"
          >
            {successMsg}
          </Alert>
        )}

        {errorMsg && (
          <Alert onClose={() => setErrorMsg("")} dismissible variant="danger">
            {errorMsg}
          </Alert>
        )}
      </Row>
    </>
  );
};

export default PaymentForm;
